import React from "react";
import classes from "./Contacts.module.css";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

const Contacts = (props) => {
  return (
    <div ref={props?.contactsRef} className={classes.contacts}>
      <div className={classes.contactsContainer}>
        <h1>{"CONTACT"}</h1>

        <a
          target={"_blank"}
          rel="noreferrer"
          href={`tel:${NAIL_SHOP_INFO.tel}`}
        >{`PHONE: ${NAIL_SHOP_INFO.phone}`}</a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`mailto:${NAIL_SHOP_INFO.email}`}
        >{`EMAIL: ${NAIL_SHOP_INFO.email}`}</a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${NAIL_SHOP_INFO.ggMapUrl}`}
        >{`ADDRESS: ${NAIL_SHOP_INFO.address}\n `}</a>
        <BookNowBtn
          onClick={() => window.open(NAIL_SHOP_INFO.ggMapUrl, "_blank")}
          isShowArrow={false}
          title={"GET DIRECTION"}
          style={classes.bookNowBtn}
        />

        <h1>{"\nOPENING HOURS"}</h1>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${NAIL_SHOP_INFO.ggMapUrl}`}
        >
          {"MONDAY - SATURDAY: 09:00 - 18:30"}
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${NAIL_SHOP_INFO.ggMapUrl}`}
        >
          {"SUNDAY: 10:30 - 17:00"}
        </a>

        <div>
          <a target={"_blank"} href={NAIL_SHOP_INFO.instagram} rel="noreferrer">
            <img
              className={classes.contactsContainerImg}
              alt={"insta"}
              g
              src={images.instaIcon}
            />
          </a>
          <a target={"_blank"} href={NAIL_SHOP_INFO.facebook} rel="noreferrer">
            <img
              className={`${classes.contactsContainerImg} ${classes.marginLeftFBIcon}`}
              alt={"facebook"}
              src={images.fbIcon}
            />
          </a>
        </div>
        <BookNowBtn isShowArrow={false} style={classes.bookNowBtn} />
      </div>

      <iframe
        title="google-map"
        className={classes.iframe}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d442.9151005693056!2d-3.0052830164088054!3d53.649059298841294!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b3f17e3fea07d%3A0x63024f10e5ab2ae4!2sAK%20Nails%20%26%20Spa!5e0!3m2!1sen!2sus!4v1719824178086!5m2!1sen!2sus"        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Contacts;
