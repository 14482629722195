import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./PriceList.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../../locales/i18n";
import Header from "../../components/Header/Header";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import { Helmet } from "react-helmet";
import { images } from "../../assets/img";
import BookNowBtn from "../../components/BookNowBtn";
import FloatingButton from "../../components/FloatingButton/FloatingButton";

const PriceList = () => {
  const location = useLocation();
  const params = useParams();
  const { state } = location || {};
  const navigate = useNavigate();
  const footerRef = useRef(null);

  const onPressMainIcon = (state) => {
    navigate("/", { state: state });
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Price list"}</title>
        <meta name="service-price-list" content="Service price list" />
      </Helmet>
      <Header
      selected={'services'}
        servicePress={() => onPressMainIcon({ focusServices: "service" })}
        introductionPress={() =>
          onPressMainIcon({ focusServices: "introduction" })
        }
        onPressMainLogo={() => onPressMainIcon({ focusServices: "service" })}
        contactPress={() => onPressMainIcon({ focusServices: "contacts" })}
      />
      <div className={classes.wrapper}>
        <img src={images.pricing} alt={"left arrow"} />
        <BookNowBtn/>
      </div>
      <FooterInfo style={classes.footerStyle} footerRef={footerRef} />
      <FloatingButton/>
    </div>
  );
};

export default PriceList;
