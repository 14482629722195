import classes from "./PrimaryBanner.module.css";
import React, { useContext, useEffect, useState, memo } from "react";
import AuthContext from "../../store/auth-context";
import { t } from "../../locales/i18n";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import { NAIL_SHOP_INFO } from "../../utils/Const";

const PrimaryBanner = (props) => {
  const {} = props || {};
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [banner, setBanner] = useState(images.nail_square);

  // useEffect(() => {
  //   const primary_banner = RemoteConfig.getConfigValue("primary_banner");
  //   primary_banner && setBanner(primary_banner);
  // }, [isFetchActivateRemoteConfig]);

  return (
    <div className={classes.primaryBanner}>
      <div className={classes.textContainer}>
        <h2>{"BRING IN\n     THE EXPERTS"}</h2>
        <h3 className={classes.textDescription}>{"Our team of expert Nail Artists is dedicated to providing you with a flawless treatment experience from start to finish. With professional training and a keen eye for detail, our Nail Artists ensure that every aspect of your nail care is handled with precision and care.\n\nFrom choosing the perfect shade to offering personalized style advice, we’re here to make your visit truly exceptional"}</h3>
        <BookNowBtn onClick={()=> window.open('tel:' + NAIL_SHOP_INFO.tel, '_self')} style={classes.callNowBtn} title={'CALL NOW'}/>
      </div>

      <div  className={classes.imgContainer}>
      <img
        className={classes.primaryBannerImage}
        alt={"Primary Banner"}
        src={banner}
      />
        <img
        className={classes.iconLogoRound}
        alt={"Primary Banner"}
        src={images.icon_logo_round}
      />
      </div>
      
    </div>
  );
};

export default memo(PrimaryBanner);
