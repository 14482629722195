import { images } from "../assets/img";

export const NAIL_SHOP_INFO = {
  ggMapUrl: "https://maps.app.goo.gl/gbGqdKhyhJwwXxf67",
  address: "18 Nevill St, Southport PR9 0BX, UK",
  email: "aknailsspa.southport@gmail.com",
  tel: "+447449043802",
  phone: "+44 744 904 3802",
  facebookId:"61560660659171",
  instagram: "https://www.instagram.com/ak_nails_spa_southport_/",
  instagramName: "ak_nails_spa_southport_",
  name: "AK Nails & Spa Southport",
  bookingUrl: "https://aknailsspabooking.setmore.com/beta"
};

NAIL_SHOP_INFO.facebook = `https://www.facebook.com/profile.php?id=${NAIL_SHOP_INFO.facebookId}`
NAIL_SHOP_INFO.facebookMessengerUrl = `http://m.me/${NAIL_SHOP_INFO.facebookId}`

export const SERVICES = [
  {
    name: "Manicures",
    description:
      "We specialize in providing clients with professional nail treatments using only the finest brands and high-quality products. With over 600 colors available and special attention paid to your skin and cuticles, our treatments will leave your nails looking fantastic.",

    image: images.nailService,
  },
  {
    name: "Pedicures",
    description:
      "Nails And Toe Are Shaped, Smoothed And Cuticle Care, Exfoliation Cucumber/Peppermint Mask, Cream And Finish With Colour Of Your Choice.",
    image: images.nailService2,
  },
  {
    name: "Our Technicians",
    description:
      "Our highly qualified team of nail technicians will take care your nails the ins and outs during your experiences at our nails bar. We always welcome you with a warm smile and make you feel at home.",
    image: images.nailService3,
  },
  {
    name: "Nails Art",
    description:
      "From classic to outstanding, our talented technicians can advise you the bespoke nail art of your imagine. Come here and enjoy your amazing nails art at our local shop",
    image: images.nailService4,
  },
];

export const LANGUAGE_ID = {
  vi: "vi",
  en: "en",
};

export const CUSTOMER_FEEDBACK = [
  { 
    name: "Sophia Martinez", 
    feedback: `“I recently visited AK Nails & Spa for a manicure and was thoroughly impressed.\nThe staff were incredibly professional, offering personalized advice on nail care. Highly recommend!”`,
    avatar: "https://i.imgur.com/92gXkP8.jpg"
  },
  { 
    name: "Emily Nguyen", 
    feedback: `“AK Nails & Spa is my go-to spot for self-care. Their pedicure service is exceptional, with a wide range of colors and finishes to choose from.Plus, their massage chairs are a slice of heaven!”`,
    avatar: "https://i.imgur.com/kI3rsKc.jpg"
  },
  { 
    name: "Olivia Smith", 
    feedback: `“I had an amazing experience at AK Nails & Spa when I booked a full set of acrylics.\nThe attention to detail was remarkable.”`,
    avatar: "https://i.imgur.com/KxhUFKM.jpg"
  },
  { 
    name: "Isabella Johnson", 
    feedback: `“I love their seasonal nail art options – they always have the perfect design to match any occasion.\nTheir warm welcome and professional service keep me coming back.”`,
    avatar: "https://i.imgur.com/bRRLNgV.jpg"
  },
  { 
    name: "Mia Robinson", 
    feedback: `“As someone who values eco-friendly products, I appreciate that AK Nails & Spa offers a range of organic polishes. Their commitment to sustainability without compromising on quality is why I’m a loyal customer.”`,
    avatar: "https://i.imgur.com/4fmU1oA.jpg"
  },
  { 
    name: "Ava Clark", 
    feedback: `“I booked a last-minute appointment at AK Nails & Spa and was delighted by how accommodating they were. My gel manicure was flawless and has remained chip-free for weeks!”`,
    avatar: "https://i.imgur.com/uNPeLg5.jpg"
  },
  { 
    name: "Charlotte Lee", 
    feedback: `“The serene atmosphere at AK Nails & Spa makes it my favorite spot for relaxation and the staff’s expertise in skin care provided me with valuable tips for maintaining healthy nails and hands between visits.”`,
    avatar: "https://i.imgur.com/aGHtXPG.jpg"
  },
  { 
    name: "Amelia Turner", 
    feedback: `“I took my mother to AK Nails & Spa for a birthday treat, and we had a wonderful time together.\nThe staff went above and beyond to ensure we felt special, offering us complimentary drinks and custom nail designs.”`,
    avatar: "https://i.imgur.com/ranRhlM.jpg"
  }
]