import React, { useContext, useEffect, useState } from "react";
import classes from "./Services.module.css";
import AuthContext from "../../store/auth-context";
import Helpers from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";
import { SERVICES } from "../../utils/Const";
import { images } from "../../assets/img";
import RemoteConfig from "../../services/RemoteConfig";
import BookNowBtn from "../BookNowBtn";

const ServiceItem = (item, index, onPress) => {
  const { name, image, description } = item || {};
  return (
    <div
      onClick={onPress?.(item)}
      key={`${name + index}`}
      className={classes.serviceItem}
    >
      <div className={`${classes.roundDiv}`}>
        <img alt={"nail"} src={image} />
      </div>

      <h3>{name}</h3>
      <h4 className={classes["change-color-on-hover"]}>{description}</h4>
    </div>
  );
};

const PricingItem = ({ name, price, price2 }) => {
  return (
    <>
      <div className={classes.pricing}>
        <h3 className={classes.alignLeft}>{name}</h3>
        <h3 className={classes.alignCenter}>{`£${price}`}</h3>
        {price2 ? (
          <h3 className={classes.alignCenter}>{`${
            price2 === " " ? "" : "£"
          }${price2}`}</h3>
        ) : null}
      </div>
    </>
  );
};

const PricingItem2 = ({ items }) => {
  return (
    <div className={classes.rowItem}>
      {items.map((item) => {
        return (
          <div key={item?.name} className={classes.pricingItem2}>
            <h3 className={classes.alignLeft}>{item?.name}</h3>
            <h3 className={classes.alignCenter}>{`${
              item?.isExtra ? "extra" : ""
            } ${item?.price === " " ? "" : "£"}${item?.price}`}</h3>
          </div>
        );
      })}
    </div>
  );
};

const Services = (props) => {
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [pricingImg,setPricingImg] = useState(images.pricing);
  const navigate = useNavigate();

  useEffect(() => {
    const introduction_image = RemoteConfig.getConfigValue(
      "pricing_image"
    );

    introduction_image && setPricingImg(introduction_image)

  }, [isFetchActivateRemoteConfig]);

  const onPress = (item) => () => {
    // navigate(`/service-details/${item?.id}`, {state: {...item}});
  };

  return (
    <div ref={props?.serviceRef} className={classes.wrapper}>
      <h1>Our Services</h1>

            <div className={classes.container}>
                {Helpers.isArrayAvailable(SERVICES)
                    ? SERVICES.map((item, index) => {
                        return ServiceItem(item, index, onPress);
                    })
                    : null}
            </div>

            <BookNowBtn style={classes.priceListBtn} onClick={()=> navigate('/price')} title={'CHECK PRICE LIST'}/>
      
    </div>
  );
};

export default Services;
