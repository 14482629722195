import React from "react";
import classes from "./Header.module.css";
import { useTranslation } from "react-i18next";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import { t } from "../../locales/i18n";
import BookNowBtn from "../BookNowBtn";
import { useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import Menu from "../Menu/";

const Header = (props) => {
  const {
    introductionRef,
    contactsRef,
    serviceRef,
    bookingRef,
    onPressMainLogo,
    introductionPress,
    servicePress,
    contactPress,
    bookingPress,
    selected,
  } = props || {};
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  const scrollToSection = (ref) => () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderLogo = () => {
    return (
      <img
        onClick={onPressMainLogo}
        className={`${classes.introLogo} ${
          onPressMainLogo ? classes.clickableLogo : null
        }`}
        alt={"main logo"}
        src={images.main_logo}
      />
    );
  };

  return (
    <div className={classes.introHeader}>
      <img
        className={classes.headerBannerImg}
        alt={"Primary Banner"}
        src={images.header_primary}
      />

      {/* {!isMobile && renderLogo()} */}

      <div className={classes.headerIntroContainer}>
        <h2>
          {isMobile
            ? "AK Nails Spa Southport"
            : "AK Nails & Spa\n          Southport"}
        </h2>
        <h3>
          {
            "Our best and professional nail\ntechnicians can't wait to amaze you\nwith the most gorgeous nails ever!"
          }
        </h3>
      </div>

      {isMobile ? (
        <Menu
          introductionRef={introductionRef}
          serviceRef={serviceRef}
          contactsRef={contactsRef}
        />
      ) : (
        <div className={classes.menuItem}>
          <h4
            onClick={() => navigate("/")}
            className={selected === "home" ? classes.selectedText : ""}
          >
            {"Home"}
          </h4>
          <h4 onClick={introductionPress || scrollToSection(introductionRef)}>
            {t("header.introduction")}
          </h4>
          <h4
            className={selected === "services" ? classes.selectedText : ""}
            onClick={() => navigate("/price")}
          >
            {t("header.service")}
          </h4>
          <h4 onClick={() => window.open(NAIL_SHOP_INFO.bookingUrl)}>
            {"Booking"}
          </h4>
          <h4 onClick={contactPress || scrollToSection(contactsRef)}>
            {t("header.contact")}
          </h4>
        </div>
      )}

      {/* {isMobile && renderLogo()} */}

      {/* <div className={classes.bookNow}>
                <a
                    className={classes.bookNowA}
                    href={`tel:${NAIL_SHOP_INFO.tel}`}
                >{`${NAIL_SHOP_INFO.phone}`}
                </a>

                <BookNowBtn style={classes.bookNowBtnHeader} isShowArrow={!isMobile}/>
            </div> */}
    </div>
  );
};

export default Header;
