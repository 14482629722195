import React, { useEffect, useRef,useContext } from "react";
import classes from "./Home.module.css";
import Header from "../../components/Header/Header";
import Services from "../../components/Services/Services";
import Introduction from "../../components/Introduction/Introduction";
import { useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PrimaryBanner from "../../components/PrimaryBanner/PrimaryBanner";
import Introduction02 from "../../components/Introduction02/Introduction02";
import InstagramFeed from "../../components/InstagramFeed/InstagramFeed";
import Contacts from "../../components/Contacts/Contacts";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import Booking from "../../components/Booking/Booking";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import CustomerFeedback from "../../components/CustomerFeedback/CustomerFeedback";
import BackToTop from '../../components/BackToTop/BackToTop'
import FloatingButton from '../../components/FloatingButton/FloatingButton'



const Home = () => {
  const location = useLocation();
  const { state } = location || {};
  const { isFocusServices, focusServices } = state || {};
  const serviceRef = useRef(null);
  const introductionRef = useRef(null);
  const contactsRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useCheckIsMobile()

  useEffect(() => {
    if (focusServices) {
      setTimeout(() => {
        let sectionRef = null
        switch (focusServices) {
          case "introduction":
            sectionRef = introductionRef
            break;
            case "service":
            sectionRef = serviceRef
            break;
            case "contacts":
              sectionRef = contactsRef
              break;
        
          default:
            sectionRef = introductionRef
            break;
        }
        sectionRef?.current?.scrollIntoView({ behavior: "smooth" });
        window.history.replaceState({}, document.title);
      }, 500);
    }
    if (searchParams.get("booking")) {
      // http://localhost:3000/?booking=true
      //TODO: Replace serviceRef with bookingRef
      serviceRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const renderTopSeparatorMobile = () => {
  if(!isMobile) return null

    return (
      <div className={classes.topSeparator}>
        <a className={classes.bookNowA} href={`tel:${NAIL_SHOP_INFO.tel}`}>
          {`PHONE: ${NAIL_SHOP_INFO.phone}`}
        </a>
      </div>
    );
  };

  return (
    <div className={classes.helloworld}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{NAIL_SHOP_INFO.name}</title>
        <meta name="service-details" content="Service description" />
        <meta name="description" content="Best Nail Bar" />
      </Helmet>
      {renderTopSeparatorMobile()}
      <Header
      selected={'home'}
        introductionRef={introductionRef}
        contactsRef={contactsRef}
        serviceRef={serviceRef}
      />
      <PrimaryBanner />
      <Introduction introductionRef={introductionRef} />
      <CustomerFeedback/>
      <Services serviceRef={serviceRef} />
      <InstagramFeed />
      <Contacts contactsRef={contactsRef} />
      <FooterInfo />
      <FloatingButton/>
    </div>
  );
};
export default Home;
