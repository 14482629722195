export const images = {
    flagUk: require('./flag-uk.png'),
    flagVn: require('./flag-vn.png'),
    fbIcon: require('./fb-icon.png'),
    messengerIcon: require('./messenger-icon.png'),
    instaIcon: require('./insta-icon.png'),
    pricing: require('./pricing.jpg'),
    nailService: require('./nail-service.png'),
    nailService2: require('./nail-service2.png'),
    nailService3: require('./nail-service3.png'),
    nailService4: require('./nail-service4.png'),
    leftArrow: require('./left-arrow.png'),
    nailImg03: require('./nail3.jpg'),
    nailImg04: require('./nail4.jpg'),
    nailImg05: require('./nail5.jpg'),
    nail1: require('./nail1.jpg'),
    nail2: require('./nail2.jpg'),
    nail6: require('./nail6.jpg'),
    nail_square: require('./nail_square.jpg'), 
    header_primary: require('./header_primary.jpg'),
    header_primary02: require('./header_primary02.jpg'),
    header_primary03: require('./header_primary03.jpg'),
    icon_logo_round: require('./icon_logo_round.png'),
    main_logo:  require('./main-logo.png'),
};
