import React, { useEffect, useState } from "react";
import classes from "./Menu.module.css";
import { useNavigate } from "react-router-dom";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import {MenuIcon} from '../../assets/icon'

const Menu = ({ isClose, introductionRef, serviceRef, contactsRef }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setIsDropdownOpen(isClose);
  }, [isClose]);

  useEffect(() => {
    window.addEventListener("scroll", detectScrollEnd);

    return () => {
      window.addEventListener("scroll", detectScrollEnd);
    };
  }, []);

  function detectScrollEnd() {
    setIsDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const onClickMenuItem = (key) => () => {
    switch (key) {
      case "home":
        navigate('/')
        break;
      case "introduction":
        introductionRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "services":
        navigate('/price')
        break;
      case "contacts":
        contactsRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "booking":
        window.open(NAIL_SHOP_INFO.bookingUrl)
        break;
      default:
        break;
    }

    toggleDropdown();
  };

  return (
    <div
      className={`${classes.mobileHeader} ${
        isDropdownOpen ? classes.open : ""
      }`}
    >
      
      <button className={classes.menuButton} onClick={toggleDropdown}>
      <MenuIcon />
      </button>
      <div
        className={`${classes.dropdownContent} ${
          isDropdownOpen ? classes.fadeIn : classes.fadeOut
        }`}
      >
        <ul>
          <li onClick={onClickMenuItem("home")}> Home</li>
          <li onClick={onClickMenuItem("introduction")}> Introduction</li>
          <li onClick={onClickMenuItem("services")}> Services </li>
          <li onClick={onClickMenuItem("booking")}>Booking</li>
          <li onClick={onClickMenuItem("contacts")}>Contacts</li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
